import React from 'react';
import VideoMasthead from '../../components/VideoMasthead';
import './AutoPage.css';

/* Example image imports */
import autoPageOne from '../../assets/autoPageOne.png';
import autoPageTwo from '../../assets/autoPageTwo.png';

const videoUrl = `https://pub-a6410c3681b541449845fc3f20fbc373.r2.dev/autoPageVideo.mp4`;

const AutoPage = () => {
  return (
    <div className="autoPageContainer">
      {/* 1. Video Masthead */}
      <VideoMasthead
        videoUrl={videoUrl}
        phrase="Convert Browsers into Buyers"
      />

      {/* 2. Title */}
      <h1 className="autoPageTitle">Automotive Marketing at Martin</h1>

      {/* 3. First Row (Image Left, Text Right) */}
      <div className="rowContainer">
        <div className="imageCol">
          <img src={autoPageOne} alt="Automotive" className="autoImage" />
        </div>
        <div className="textCol">
          <h2>Strategy</h2>
          <p>
            Digital? Traditional? OTT? CTV? OLV? PPC? Social? Native?
            Not sure where to spend your dollars or what to say effectively?
            We guide you to an amplified go-to-market message.
          </p>
          <h2>Accountability</h2>
          <p>
            One POINT OF CONTACT for your integrated marketing strategy
            and tactics. If something is broken, WE FIX IT.
          </p>
          <h2>Expertise</h2>
          <p>
            Our success is reflected in our clients' results.
            Let us show you where decades of automotive expertise can take you.
          </p>
        </div>
      </div>

      {/* 4. Second Row (Text Left, Image Right) with Gray BG (#E2E2E2) */}
      <div className="rowContainer grayBackground">
        <div className="textCol">
          <h2>Martin as your Turn-Key Agency</h2>
          <p>
            Participation in GM's iMR programs has benefits:
          </p>
          <ul>
            <li>Proven GM-certified solutions</li>
            <li>Integration with GM incentives</li>
            <li>Brand-compliant templates & creative</li>
            <li>Tier 1, 2, and 3 alignment</li>
            <li>Heavy UPS & Marketing initiatives</li>
            <li>Funds reimbursement*</li>
          </ul>
          <p className="note">
            *For programs that offer funds reimbursement. Percentages may vary.
          </p>
        </div>
        <div className="imageCol">
          <img src={autoPageTwo} alt="Automotive Tire" className="autoImage" />
        </div>
      </div>

      {/* 5. Our Digital Media Philosophy (White BG) */}
      <div className="philosophySection">
        <p>
          <span className="inlineTitle">Our Digital Media Philosophy</span> centers on authenticity,
          precision, and adaptability, making digital media a bridge that delivers value
          at every interaction between dealerships and customers. We view digital as a dynamic
          ecosystem, merging data insights with creative content to build trust and convert
          browsers into buyers.
        </p>
      </div>

      {/* CREATIVE SERVICES SECTION */}
      <div className="creativeServicesSection grayBackground">
        <div className="innerWrapper">
          <h2>Creative Services</h2>
          <div className="creativeRow">
            <div className="leftCol">
              <p>
                Foundational to this is our test-and-learn approach, where campaigns evolve through
                continuous testing and refinement. By analyzing performance outcomes, we adapt to
                changing consumer behaviors and trends, ensuring our strategies are both impactful
                today and flexible for the future, fostering sustained growth for our dealerships.
              </p>
            </div>
            <div className="rightCol">
              <p>
                At Martin, we tailor national creative to meet local dealer needs, adding custom
                elements like end cards or L-wraps to seamlessly integrate dealership info. This
                approach balances personalized, brand-aligned ads with cost efficiency, providing
                high-impact, fast-turnaround spots that deliver national quality with local relevance.
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default AutoPage;
